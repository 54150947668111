import React, {useEffect, useRef} from "react"
import {useTranslation} from "react-i18next"

import './platform.component.less';
import * as $ from "jquery";

import {useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../seo";
import MainImg from "../../assets/img/software/software_big.jpg";
import SmallImg from "../../assets/img/software/software_small.jpg";
import SoftwarePoster1 from "../../assets/img/software/software_poster_1.png";
import software1 from "../../assets/video/software-1.mp4";
import Software from "../../assets/img/software/software.jpg";

const PlatformComponent = () => {
    const {i18n, t} = useTranslation();
    const {originalPath} = useI18next();

    useEffect(() => {
        $('a[href^="#"]').click(function () {
            let elementClick = $(this).attr("href");
            let destination = $(elementClick).offset().top - 100;
            $('html, body').animate({
                scrollTop: destination
            }, 450);
            return false;
        });
    });

    return (
        <div>
            <Seo description={t("metaDescriptionSmartCity")}
                 lang={i18n.language} title={t("titleSmartCity")}/>
            <div className="software">
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block wrapper_block__block--no-border">
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span
                                dangerouslySetInnerHTML={{__html: t("cityAirQualityMonitoringSoftware")}}/>
                            <div className="main-block__title-purple"
                                 dangerouslySetInnerHTML={{__html: t("cityAirQualityMonitoringSoftware2")}}/>
                        </div>
                        <div className="main-block__description main-block__description--single">
                            <div dangerouslySetInnerHTML={{__html: t("cityScreenPlatformWhichUnites")}}/>
                        </div>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={MainImg}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                     className="main-block__small-img"
                                     src={SmallImg}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border wrapper_block--yellow big-title">
                    <div className="big-title__width">
                        <div
                            dangerouslySetInnerHTML={{__html: t("basicFunctions")}}/>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block--yellow wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div className="borders-block__col--title">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("ecologicalProfile")}}/>
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point ">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("topLevelStatisticsAnd")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div className="borders-block__col--title">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("dataFromMonitorsAndLocations")}}/>
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point ">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("pollutantsConcentrationDiagrams")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div className="borders-block__col--title">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("reportTool")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal borders-block__hr--mobile"/>
                        </div>
                        <div className="borders-block__hr"/>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4
                                </div>
                                <div className="borders-block__col--title">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("differentAccessLevels")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">5
                                </div>
                                <div className="borders-block__col--title">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("remoteDiagnosticsAnd")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">6
                                </div>
                                <div className="borders-block__col--title">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("cloudAPIOrStand")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper_block wrapper_block__block wrapper_block--yellow borders-block software__img_2--wrapper wtu__img--wrapper">
                    <div className="software__video">
                        <img className="wtu__img" src={Software} />
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block">
                    <div className="platform-scheme">
                        <div className="platform-scheme__col-2">
                            <div className="platform-scheme__block platform-scheme__block-standard borders-block__col--big-text--2 platform-scheme__block__bottom-arrow">
                                Fully functional web UI
                            </div>
                            <div className="platform-scheme__block platform-scheme__block-standard borders-block__col--big-text--2 platform-scheme__block__bottom-arrow">
                                API
                            </div>
                        </div>
                        <div className="platform-scheme__col-1">
                            <div className="platform-scheme__block platform-scheme__block-link">
                                <div className="platform-scheme__block-title platform-scheme__block-title_center borders-block__col--big-text--2">Airvoice Platform</div>
                                <ul className="platform-scheme__block-list platform-scheme__block-list_main">
                                <li className="platform-scheme__block-list__item borders-block__col--point">Seamless integration</li>
                                    <li className="platform-scheme__block-list__item borders-block__col--point">Data aggregation</li>
                                    <li className="platform-scheme__block-list__item borders-block__col--point">Data verification</li>
                                </ul>
                            </div>
                        </div>
                        <div className="platform-scheme__col-2 platform-scheme__mobile-col-1 platform-scheme__mobile-line">
                            <div className="platform-scheme__col-2 platform-scheme__mobile-col-1 platform-scheme__equal-blocks">
                                <div className="platform-scheme__block platform-scheme__block__top-arrow">
                                    <a href="/equipment/" className="platform-scheme__block-link">
                                        <div className="platform-scheme__block-title borders-block__col--big-text--2">Airvoice Hardware</div>
                                        <ul className="platform-scheme__block-list platform-scheme__block-list_standard">
                                            <li className="platform-scheme__block-list__item borders-block__col--point">Air Quality Monitor</li>
                                            <li className="platform-scheme__block-list__item borders-block__col--point">Airvoice sensors (PM2.5, PM10, CO, NO<sub>2</sub>, O<sub>3</sub>, SO<sub>2</sub>, H<sub>2</sub>S</li>
                                            <li className="platform-scheme__block-list__item borders-block__col--point">Modbus HUB</li>
                                            <li className="platform-scheme__block-list__item borders-block__col--point">Meteostation</li>
                                        </ul>
                                    </a>
                                </div>
                                <div className="platform-scheme__block platform-scheme__block__top-arrow">
                                    <a href="/modeling/" className="platform-scheme__block-link">
                                        <div className="platform-scheme__block-title  borders-block__col--big-text--2">Airvoice Software</div>
                                        <ul className="platform-scheme__block-list platform-scheme__block-list_standard">
                                            <li className="platform-scheme__block-list__item borders-block__col--point">Airvoice.Forecast</li>
                                            <li className="platform-scheme__block-list__item borders-block__col--point">Airvoice.Plume</li>
                                            <li className="platform-scheme__block-list__item borders-block__col--point">Airvoice.Lens</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                            <div className="platform-scheme__col-3">
                                <div className="platform-scheme__block platform-scheme__block-standard borders-block__col--big-text--2 platform-scheme__block__top-arrow">
                                    External hardware
                                </div>
                                <div className="platform-scheme__block platform-scheme__block-standard borders-block__col--big-text--2 platform-scheme__block__top-arrow">
                                    External software
                                </div>
                                <div className="platform-scheme__block platform-scheme__block-standard borders-block__col--big-text--2 platform-scheme__block__top-arrow">
                                    Data
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border big-title big-title__platform">
                    <div className="big-title__width">
                        <div
                            dangerouslySetInnerHTML={{__html: t("whatCanWeDo")}}/>
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block what-can__block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <ul className="platform__list">
                                <li className="platform__list-item borders-block__col--big-text--2">Identification of unknown pollution source based on data analysis</li>
                                <li className="platform__list-item borders-block__col--big-text--2">Identification of influence of the industrial unit (or any other source) on air quality in the area</li>
                                <li className="platform__list-item borders-block__col--big-text--2">Modelling of possible consequences of city area development</li>
                                <li className="platform__list-item borders-block__col--big-text--2">Best optimal approach for monitoring networks deployment</li>
                            </ul>
                        </div>
                        <div className="borders-block__col-right">
                            <div className="platform__list-title borders-block__col--title">Additional Services</div>
                            <ul className="platform__list">
                                <li className="platform__list-item borders-block__col--point">Air Quality Monitoring Hardware Installation and Service</li>
                                <li className="platform__list-item borders-block__col--point">Training programs for New users</li>
                                <li className="platform__list-item borders-block__col--point"> Support Hotline</li>
                                <li className="platform__list-item borders-block__col--point">Full customization in accordance with customer needs</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    className="link_and__end-block wrapper_block--padding-bottom wrapper_block wrapper_block__block wrapper_block__block--no-border" id="contacts">
                    <span className="link_and__mail"
                          dangerouslySetInnerHTML={{__html: t("ToGetMoreInformation")}}/>
                </div>
            </div>
        </div>
    )
}

PlatformComponent.propTypes = {}

PlatformComponent.defaultProps = {}

export default PlatformComponent
